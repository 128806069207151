const getReimbursement = (state) => state.reimbursementsReducer;

export const selectIsPageLevelError = (state) => getReimbursement(state).isPageLevelError;
export const selectIsReimbursementDeletionError = (state) => getReimbursement(state).isReimbursementDeletionError;
export const selectIsReimbursementDeletionSuccess = (state) => getReimbursement(state).isReimbursementDeletionSuccess;
export const selectPaginationInfo = (state) => getReimbursement(state).paginationInfo;
export const selectIsLoading = (state) => getReimbursement(state).isLoading;
export const selectIsLoadingPage = (state) => getReimbursement(state).isLoadingPage;
export const selectReimbursementsList = (state) => getReimbursement(state).reimbursementsListView;
export const selectShouldOpenDeleteConfirmationPopup = (state) => getReimbursement(state).shouldOpenDeleteConfirmationPopup;
export const selectIsCreateReimbursementsSuccess = state => getReimbursement(state).isCreateReimbursementSuccess;
export const selectIsCreateReimbursementsError = state => getReimbursement(state).isCreateReimbursementError;
export const selectformFieldValueMap = state => getReimbursement(state).formFieldValueMap;
export const selectIsReimbursementDetailSuccess = state => getReimbursement(state).isReimbursementDetailSuccess;
export const selectError = state => getReimbursement(state).error;
export const selectIsReimbursementDetailError = state => getReimbursement(state).isReimbursementDetailError;
export const selectIsEditReimbursementSuccess = state => getReimbursement(state).isEditReimbursementSuccess;
export const selectIsEditReimbursementError = state => getReimbursement(state).isEditReimbursementError;
