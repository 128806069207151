import { API_URL } from "../../../constants/urlConstants";
import { transformTypesToDropDown } from "../../../utils/dropDown/transformtoDropdown";

export const getTypesReducer = state => state.typesReducer;

const getEquipmentTypes = state => getTypesReducer(state).types[API_URL.TYPES.EQUIP_TYPE]
export const getEquipmentTypesList = state => transformTypesToDropDown(getEquipmentTypes(state));


const getVehicleTypes = state => getTypesReducer(state).types[API_URL.TYPES.VEHICLE_TYPE]
export const getVehicleTypesList = state => transformTypesToDropDown(getVehicleTypes(state));

const getTrackingDeviceProvider = state => getTypesReducer(state).types[API_URL.TYPES.TRACKING_DEVICE_PROVIDER]
export const getTrackingDeviceProvidingList = state => transformTypesToDropDown(getTrackingDeviceProvider(state));

const getAssetTrackingStatus = state => getTypesReducer(state).types[API_URL.TYPES.ASSET_TRACKING_STATUS];
export const getAssetTrackingStatusList = state => transformTypesToDropDown(getAssetTrackingStatus(state));

const getReimbursementStatus = state => getTypesReducer(state).types[API_URL.TYPES.REIMB_STATUS];
export const getReimbursementStatusList = state => transformTypesToDropDown(getReimbursementStatus(state));

export const getTaxTypes = state => getTypesReducer(state).types[API_URL.TYPES.TAX_TYPE]
export const getTaxTypesList = state =>transformTypesToDropDown(getTaxTypes(state), 'value', 'key');
