import Cookies from 'universal-cookie';
import { post, put } from 'axios';
import { getApiAsyn ,deleteApi, baseUrls} from '../../PostAPI';
import { handleAPIError, APIError } from '../../common/errorHandler';
import { API_URL } from '../../../constants/urlConstants';
import { generateImageUrl, getDecoratedUrl } from '../../common/urlService';
import { extractData } from '../../utils';
import { asyncForEach } from '../../../utils/async';
import { getAllAttachment } from '../commonService';

const cookies = new Cookies();

export const fetchReimbursements = async (payload) => {
    const url = getDecoratedUrl({
      url: API_URL.REIMBURSEMENTS.FETCH_REIMBURSEMENT,
      payload,
    });
    try {
      let result = await getApiAsyn(url, cookies.get('authorization'));
  
      if (result?.error) {
        throw new APIError(result);
      }
  
      return extractData(result, !payload?.filters);
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };
  
  export const deleteReimbursementRecord = async ({ reimbursementId }) => {
    try {
      let result = await deleteApi(
        `${API_URL.REIMBURSEMENTS.DELETE_REIMBURSEMENT}/${reimbursementId}`,
        cookies.get('authorization')
      );
  
      if (result?.error) {
        throw new APIError(result);
      }
      return result;
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };  

  
  const multipartDataUploadApi = (url, { files, data }, isEdit) => {
    const formPayload = new FormData();
    //const baseUrl = 'https://api.ezonbooks.net/api/v2/';
    const baseUrl = baseUrls
    // data part of multipart data
    // data.date = 1346524199000
    const json = JSON.stringify(data);
    const blobData = new Blob([json], {
      type: 'application/json',
    });
    formPayload.append('data', blobData);
    
    // file part of multipart data
    files?.forEach((file) => {
      formPayload.append('file', file);
    });
  
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: cookies.get('authorization'),
      },
    };
    if (isEdit) {
      return put(baseUrl + url, formPayload, config);
    } else {
      return post(baseUrl + url, formPayload, config);
    }
  };

  export const createReimbursementRecord = async (payload) => {
    try {
      let result = await multipartDataUploadApi(
          `${API_URL.REIMBURSEMENTS.CREATE_REIMBURSEMENT}`,
        payload
      );
  
      if (result?.error) {
        throw new APIError(result);
      }
      return result;
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };

  export const editReimbursementRecord = async (payload) => {
    const url = API_URL.REIMBURSEMENTS.EDIT_REIMBURSEMENTS.replace(
      ':id',
      payload?.reimbursementId
    );
  
    try {
      let result = await multipartDataUploadApi(url, payload, true);
  
      if (result?.error) {
        throw new APIError(result);
      }
      return result;
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };
  export const fetchReimbursementByReimbursementId = async (payload) => {
    const url = API_URL.REIMBURSEMENTS.FETCH_BY_REIMBURSEMENT_ID.replace(
      ':id',
      payload?.reimbursementId
    );
    
    try {
      let result = await getApiAsyn(url, cookies.get('authorization'));
  
      if (result?.error) {
        throw new APIError(result);
      }
      
      result = extractData(result, !payload?.filters);
      if (result?.files?.length) {
        await asyncForEach(result.files, async (file, index, array) => {
          //result.files[index].url = await generateImageUrl(file.url);
          result.files[index].s3Url = await generateImageUrl(file.url);
        });
      }
      return result;
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };


  export const fetchReimbursementsAttachmentByReimbursementId = async (payload) => {
    const url = API_URL.REIMBURSEMENTS.FETCH_BY_REIMBURSEMENT_ID.replace(':id',payload?.itemId);
    try {
      let result = await getApiAsyn(url, cookies.get('authorization'));
  
      if (result?.error) {
        throw new APIError(result);
      }
      if (result?.files?.length) {
        await asyncForEach(result.files, async (file, index, array) => {
          // result.files[index].url = await generateImageUrl(file.url);
          result.files[index].s3Url = await generateImageUrl(file.url);
        });
      }
      return result;
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };
  
  export const downloadReimbursementsAllAttachment = async (payload) => {
    await getAllAttachment('reimbursements', payload)
  };
  
  export const fetchTrips = async (payload) => {
    const url = API_URL.FETCH_TRIPS;
    try {
      let result = await getApiAsyn(url, cookies.get('authorization'));
  
      if (result?.error) {
        throw new APIError(result);
      }
  
      return extractData(result, !payload?.filters);
    } catch (error) {
      console.error(error);
      return handleAPIError(error);
    }
  };
