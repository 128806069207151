import {
  INIT_DISPATCHED_TRIPS,
  FETCH_DISPATCHED_TRIPS_LIST_START,
  FETCH_DISPATCHED_TRIPS_LIST_SUCCESS,
  FETCH_DISPATCHED_TRIPS_LIST_ERROR,
  DELETE_DISPATCHED_TRIP_RECORD_ERROR,
  DELETE_DISPATCHED_TRIP_RECORD_SUCCESS,
  DELETE_DISPATCHED_TRIP_RECORD_START,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  INIT_CREATE_NEW_DISPATCHED_TRIP_SCREEN,
  CREATE_DISPATCHED_TRIP_RECORD,
  EDIT_DISPATCHED_TRIP_RECORD,
  FETCH_DISPATCHED_TRIP_DETAIL,
  TOAST
} from '../../../constants/action-types/dispatchedTrip';
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from '../../../utils/table/gridUtils';
import { buildName } from '../../../utils/string';

export const DISPATCHED_TRIP_DATE_DATA_KEY = 'date';
export const DRUG_CHECK_DATE_KEY = 'drug_chk_dt';

const isDateField = (key) => {
  const dateFields = [DISPATCHED_TRIP_DATE_DATA_KEY, DRUG_CHECK_DATE_KEY];
  return dateFields.includes(key);
};

const tranformToAccidentGridView = (dispatchedTripData) => {
  const rows = dispatchedTripData.map((dispatchedTrip) => {
    const {
      id,
      trip_no,
      items,
      driver1,
      vehicle,
      trailer1,
      desc,
      date,
    } = dispatchedTrip;

    const transformedValues = {
      id,
      date,
      driver1: {
        name: buildName(driver1),
        toolTip: 'Testing tooltip', // Driver Name - driver1.first + last ( Add tooltip - show supplier name)
      },
      trip_no,
      orderId: items?.reduce((acc, item) => (acc + item.id + ', '), '')?.slice(0, -2) || '-',
      vehicle: vehicle?.asset_code,
      trailer1: trailer1?.asset_code,
      customer: items?.reduce((acc, item) => (acc + item.order?.billed_to?.name + ', '), '')?.slice(0, -2) || '-',
      route: items?.reduce((acc, item) => (acc + item.city + ' -> '), '')?.slice(0, -4) || '-',
      nextAvailability: items[items.length - 1].dep_date || 'Not available'
    };
    const decoratedValues = {};
    Object.keys(transformedValues).forEach((key) => {
      decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
    });
    return decoratedValues;
  });

  const columns = [
    {
      colLabel: 'Trip #',
      dataKey: 'trip_no',
      extraProps: setColumnDefaultExtraProps({
        hidden: false,
        isSortable: false,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'Order #',
      dataKey: 'orderId',
      extraProps: setColumnDefaultExtraProps({
        hidden: false,
        isSortable: false,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'Customer',
      dataKey: 'customer',
      extraProps: setColumnDefaultExtraProps({
        hidden: false,
        isSortable: false,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'Truck',
      dataKey: 'vehicle',
      extraProps: setColumnDefaultExtraProps({
        filterField: 'vehicle.asset_code',
        sortField: 'vehicle.asset_code',
      }),
    },
    {
      colLabel: 'Trailer1',
      dataKey: 'trailer1',
      extraProps: setColumnDefaultExtraProps({
        shouldFilter: true,
        isSortable: true,
      }),
    },
    {
      colLabel: 'Trailer 2#',
      dataKey: 'id',
      extraProps: setColumnDefaultExtraProps({
        hidden: false,
        isSortable: false,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'Driver 1 Name',
      dataKey: 'driver1.name',
      toolTipKey: 'driverInfo.toolTip',
      extraProps: setColumnDefaultExtraProps({
        filterField: 'driver1.first',
        sortField: 'driver1.first',
      }),
    },
    {
      colLabel: 'Driver 2 Name',
      dataKey: 'driver2.name',
      toolTipKey: 'driverInfo.toolTip',
      extraProps: setColumnDefaultExtraProps({
        filterField: 'driver2.first',
        sortField: 'driver2.first',
      }),
    },
    {
      colLabel: 'Direction',
      dataKey: 'desc',
      extraProps: setColumnDefaultExtraProps({ shouldFilter: false }),
    },
    {
      colLabel: 'Route',
      dataKey: 'route',
      extraProps: setColumnDefaultExtraProps({ filterField: 'location' }),
    },
    {
      colLabel: 'Status',
      dataKey: 'isInjured',
      extraProps: setColumnDefaultExtraProps({
        isSortable: false,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'Next availability',
      dataKey: 'nextAvailability',
      extraProps: setColumnDefaultExtraProps({
        isSortable: false,
        shouldFilter: false,
      }),
    },
  ];

  return {
    dispatchedTripsListView: { columns, rows },
  };
};
const formFieldValueMap = {
  location: '',
  desc: '',
  [DISPATCHED_TRIP_DATE_DATA_KEY]: null,
  injury: false,
  damages: false,
  at_fault: false,
  liability: false,
  drug_chk: false,
  claim_wt_rcvry: 0,
  claim_wo_rcvry: 0,
  impact_rate: 0,
  impact_result: '',
  rem_action: '',
  driver1: null,
  driver2: null,
  injury_desc: '',
  damage_desc: '',
  at_fault_desc: '',
  liability_desc: '',
  drug_chk_desc: '',
  [DRUG_CHECK_DATE_KEY]: '',
  drug_chk_rslt: null,
  province: '',
  truck: null,
  truck_owner: '',
  trailer1: null,
  trailer1_owner: '',
  trailer2: null,
  trailer2_owner: '',
  owner_opr: '',
  trip_no: '',
  claim_no: '',
  // TODO (subham) - Revisit if we should keep this field in formFieldValueMap
  files: [],
};

const INITIAL_STATE = {
  dispatchedTripsListView: {},
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateAccidentSuccess: false,
  isCreateAccidentError: false,
};

export const dispatchedTripsReducer = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case INIT_DISPATCHED_TRIPS:
      return { ...INITIAL_STATE, isLoadingPage: true };

    case FETCH_DISPATCHED_TRIPS_LIST_START:
    case CREATE_DISPATCHED_TRIP_RECORD.START:
    case DELETE_DISPATCHED_TRIP_RECORD_START:
    case FETCH_DISPATCHED_TRIP_DETAIL.START:
    case EDIT_DISPATCHED_TRIP_RECORD.START:
      return { ...state, isLoading: true };
    case FETCH_DISPATCHED_TRIPS_LIST_SUCCESS: {
      const { page, content } = action.payload;
      let resetState = {
        dispatchedTripsListView: null,
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isCreateAccidentSuccess: false,
        isCreateAccidentError: false,
      };

      if (!content) {
        return resetState;
      }
      const { number, size, total_elements: totalElements, total_pages: totalPages } = page;
      const { dispatchedTripsListView } = tranformToAccidentGridView(content);

      return {
        ...resetState,
        dispatchedTripsListView,
        paginationInfo: {
          pageSize: size,
          totalRecords: totalElements,
          totalPages,
          pageNumber: number,
        },
      };
    }

    case FETCH_DISPATCHED_TRIPS_LIST_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        dispatchedTripsListView: {},
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
        isAccidentDeletionSuccess: false,
        isAccidentDeletionError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_DISPATCHED_TRIP_RECORD_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isAccidentDeletionSuccess: false,
        isAccidentDeletionError: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_DISPATCHED_TRIP_RECORD_SUCCESS: {
      return {
        ...state,
        isAccidentDeletionSuccess: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
      const shouldOpenDeleteConfirmationPopup = action.payload;
      return {
        ...state,
        shouldOpenDeleteConfirmationPopup,
        isAccidentDeletionError: false,
        isAccidentDeletionSuccess: false,
      };
    }

    // TODO(subham): See if this field is needed.
    case INIT_CREATE_NEW_DISPATCHED_TRIP_SCREEN: {
      return { ...INITIAL_STATE };
    }

    case CREATE_DISPATCHED_TRIP_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isCreateAccidentSuccess: false,
        isCreateAccidentError: true,
      };
    }
    case CREATE_DISPATCHED_TRIP_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isCreateAccidentSuccess: true,
        isCreateAccidentError: false,
      };
    }

    case FETCH_DISPATCHED_TRIP_DETAIL.SUCCESS: {
      const temp = {};
      Object.keys(formFieldValueMap).forEach((key) => {
        const value = action.payload[key];
        temp[key] = value && isDateField(key) ? new Date(value) : value;
      });

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isAccidentDetailSuccess: true,
      };
    }

    case FETCH_DISPATCHED_TRIP_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isAccidentDetailSuccess: false,
        isAccidentDetailError: true,
      };
    }

    case EDIT_DISPATCHED_TRIP_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isEditAccidentSuccess: false,
        isEditAccidentError: true,
      };
    }

    case EDIT_DISPATCHED_TRIP_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isEditAccidentSuccess: true,
        isEditAccidentError: false,
      };
    }
    case TOAST.RESET: {
      return {
        ...state,
        isCreateAccidentSuccess: false,
        isCreateAccidentError: false,
        isEditAccidentError: false,
        isEditAccidentSuccess: false,
        isAccidentDetailError: false,
      };
    }
    default:
      return state;
  }
};
