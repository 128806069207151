import React from 'react';
import { Route } from 'react-router-dom';
import ReimbursementsScreen from '../../containers/Reimbursement_new';
import CreateReimbursementScreen from '../../containers/Reimbursement_new/CreateReimbursement';
//pimport CreateReimbursementScreen from '../../Reimbursement/CreateReimbursement';
import { APP_URLS } from '../../constants/urlConstants';

const ReimbursementRoutes = (props) => {
  const { access, match } = props;
  const { create, read, update } = access;
  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <ReimbursementsScreen {...routeProps} screenPermissions={access} />
          )}
        />
      )}
      {!!create && (
        <Route
          path={APP_URLS.REIMBURSEMENTS.ADD_REIMBURSEMENTS}
          component= {CreateReimbursementScreen}
        />
      )}
      {!!update && (
        <Route
          path={APP_URLS.REIMBURSEMENTS.EDIT_REIMBURSEMENTS}
          render={(routeProps) => (
            <CreateReimbursementScreen {...routeProps} isEdit={true} />
          )}
        />
      )}
    </>
  );
};

export default ReimbursementRoutes;
