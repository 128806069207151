import {
  INIT_REIMBURSEMENTS,
  FETCH_REIMBURSEMENTS_LIST_START,
  FETCH_REIMBURSEMENTS_LIST_SUCCESS,
  FETCH_REIMBURSEMENTS_LIST_ERROR,
  DELETE_REIMBURSEMENT_RECORD_ERROR,
  DELETE_REIMBURSEMENT_RECORD_SUCCESS,
  DELETE_REIMBURSEMENT_RECORD_START,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  INIT_CREATE_NEW_REIMBURSEMENT_SCREEN,
  CREATE_REIMBURSEMENT_RECORD,
  EDIT_REIMBURSEMENT_RECORD,
  FETCH_REIMBURSEMENT_DETAIL,
  TOAST
} from '../../../constants/action-types/Reimbursement';
import { decorateEmptyGridValues, setColumnDefaultExtraProps } from '../../../utils/table/gridUtils';

export const REIMBURSEMENT_DATE_DATA_KEY = 'reimb_date';

const isDateField = (key) => {
  const dateFields = [REIMBURSEMENT_DATE_DATA_KEY];
  return dateFields.includes(key);
};

const tranformToReimbursementGridView = (reimbursementData) => {
  const rows = reimbursementData.map((reimbursement) => {
    const {
      id,
      paid_to,
      reimb_date,
      reimb_status,
      trip_type,
      trip,
      manual_trip,
      ttl_amt,
    } = reimbursement;

    const transformedValues = {
      id,
      reimb_date,
      type: 'Reimbursement',
      reimb_status : reimb_status?.name,
      trip : trip_type ? manual_trip : trip?.trip_no,
      paid_to,
      ttl_amt,
    };

  const decoratedValues = {};
    Object.keys(transformedValues).forEach((key) => {
      decoratedValues[key] = decorateEmptyGridValues(transformedValues[key]);
    });
    return decoratedValues;
  });

  const columns = [
    {
      colLabel: 'Column Id',
      dataKey: 'id',
      extraProps: setColumnDefaultExtraProps({
        hidden: true,
        isSortable: false,
        shouldFilter: false,
      }),
    },
    {
      colLabel: 'Date',
      dataKey: REIMBURSEMENT_DATE_DATA_KEY,
      extraProps: setColumnDefaultExtraProps({
        isDateField: true,
        shouldFilter: false
      }),
    },
    {
      colLabel: 'Type',
      dataKey: 'type',
    },
    {
      colLabel: 'Status',
      dataKey: 'reimb_status',
      extraProps: setColumnDefaultExtraProps({
        filterField: 'reimb_status.name',
        sortField: 'reimb_status.name'
      }),
    },
    {
      colLabel: 'Trip No',
      dataKey: 'trip',
      extraProps: setColumnDefaultExtraProps({
        filterField: 'trip.trip_no',
        sortField: 'trip.trip_no',
        isSortable: true,
        shouldFilter: false
      }),
    },
    {
      colLabel: 'Paid To',
      dataKey: 'paid_to',
      extraProps: setColumnDefaultExtraProps({ 
        shouldFilter: true,
        isSortable : true
       })
    },
    {
      colLabel: 'Total Amount',
      dataKey: 'ttl_amt',
      extraProps: setColumnDefaultExtraProps({ 
        shouldFilter: true,
        isSortable : true
       })
    }
  ];

  return {
    reimbursementsListView: { columns, rows },
  };
};

const formFieldValueMap = {
  reimb_date: '',
  driver_id: '',
  type : '',
  reimb_status : '',
  trip : '',
  paid_to: '',
  ttl_amt : '',
  tax : '',
  txn_amt: '',
  hst_amt: '',
  trip_id: '',
  trip_type: false,
  manual_trip: '',
  files: []
};

const INITIAL_STATE = {
  reimbursementsListView: {},
  isLoading: false,
  error: null,
  formFieldValueMap,
  isCreateReimbursementSuccess: false,
  isCreateReimbursementError: false,
};

export const reimbursementsReducer = (state = INITIAL_STATE, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case INIT_REIMBURSEMENTS:
      return { ...INITIAL_STATE, isLoadingPage: true };

    case FETCH_REIMBURSEMENTS_LIST_START:
    case CREATE_REIMBURSEMENT_RECORD.START:
    case DELETE_REIMBURSEMENT_RECORD_START:
    case FETCH_REIMBURSEMENT_DETAIL.START:
    case EDIT_REIMBURSEMENT_RECORD.START:
      return { ...state, isLoading: true };
    case FETCH_REIMBURSEMENTS_LIST_SUCCESS: {
      const { page, content } = action.payload;
      let resetState = {
        reimbursementsListView: null,
        paginationInfo: null,
        error: null,
        isLoading: false,
        isLoadingPage: false,
        isPageLevelError: false,
        isCreateReimbursementSuccess: false,
        isCreateReimbursementError: false,
      };

      if (!content) {
        return resetState; 
      }
      console.log(page, 'page')
      const { number, size, total_elements, total_pages } = page;
      const { reimbursementsListView } = tranformToReimbursementGridView(content);

      return {
        ...resetState,
        reimbursementsListView,
        paginationInfo: {
          pageSize: size,
          totalRecords: total_elements,
          totalPages: total_pages,
          pageNumber: number,
        },
      };
    }

    case FETCH_REIMBURSEMENTS_LIST_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        reimbursementsListView: {},
        isLoading: false,
        isLoadingPage: false,
        error,
        isPageLevelError: true,
        isReimbursementDeletionSuccess: false,
        isReimbursementDeletionError: false,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_REIMBURSEMENT_RECORD_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        isLoading: false,
        isLoadingPage: false,
        error,
        isReimbursementDeletionSuccess: false,
        isReimbursementDeletionError: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case DELETE_REIMBURSEMENT_RECORD_SUCCESS: {
      return {
        ...state,
        isReimbursementDeletionSuccess: true,
        shouldOpenDeleteConfirmationPopup: false,
      };
    }

    case SET_DELETE_CONFIRMATION_POPUP_VISIBILITY: {
      const shouldOpenDeleteConfirmationPopup = action.payload;
      return {
        ...state,
        shouldOpenDeleteConfirmationPopup,
        isReimbursementDeletionError: false,
        isReimbursementDeletionSuccess: false,
      };
    }

    // TODO(subham): See if this field is needed.
    case INIT_CREATE_NEW_REIMBURSEMENT_SCREEN: {
      return { ...INITIAL_STATE };
    }

    case CREATE_REIMBURSEMENT_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isCreateReimbursementSuccess: false,
        isCreateReimbursementError: true,
      };
    }
    case CREATE_REIMBURSEMENT_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isCreateReimbursementSuccess: true,
        isCreateReimbursementError: false,
      };
    }

    case FETCH_REIMBURSEMENT_DETAIL.SUCCESS: {
      const temp = {};
      Object.keys(formFieldValueMap).forEach((key) => {
        const value = action.payload[key];
        temp[key] = value && isDateField(key) ? new Date(value) : value;
      });

      return {
        ...state,
        isLoading: false,
        formFieldValueMap: temp,
        isReimbursementDetailSuccess: true,
      };
    }

    case FETCH_REIMBURSEMENT_DETAIL.ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        isReimbursementDetailSuccess: false,
        isReimbursementDetailError: true,
      };
    }

    case EDIT_REIMBURSEMENT_RECORD.ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        isEditReimbursementSuccess: false,
        isEditReimbursementError: true,
      };
    }

    case EDIT_REIMBURSEMENT_RECORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isEditReimbursementSuccess: true,
        isEditReimbursementError: false,
      };
    }
    case TOAST.RESET: {
      return {
        ...state,
        isCreateReimbursementSuccess: false,
        isCreateReimbursementError: false,
        isEditReimbursementError: false,
        isEditReimbursementSuccess: false,
        isReimbursementDetailError: false,
      };
    }
    default:
      return state;
  }
};
