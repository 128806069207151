import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from '../Login/Login';
import CustomerAndBrokers from '../Customers/CustomerAndBrokers/CustomerAndBrokers';
import Invoices from '../Customers/Invoices/Invoices';
import Dashboard from '../Dashboard/Dashboard';
import CreateCustomer from '../Customers/CustomerAndBrokers/CreateCustomer';
import Orders from '../Fleet/Orders/Orders';
import CreateOrders from '../Fleet/Orders/CreateOrders';
import Trips from '../Fleet/Trips/Trips';
import CreateTrips from '../Fleet/Trips/CreateTrips';
import Profile from '../Profile/Profile';
import OrderTrips from '../Fleet/Orders/OrderTrips';
//import CreateAssets from '../containers/Assets/createAsset';
//import Reimbursement from '../Reimbursement/Reimbursement';

import Reimbursement from '../containers/Reimbursement_new';
import CreateReimbursement from '../containers/Reimbursement_new/CreateReimbursement';
//import CreateReimbursement from '../Reimbursement/CreateReimbursement';

import NotFound from '../NotFound/NotFound';
import AddBorderConnect from '../BorderConnect/AddBorderConnect';
import CreateEmployee from '../Employee/CreateEmployee';
import DocumentNew from '../containers/Documents_new';
import Employees from '../containers/EmployeeScreen';
import { APP_URLS } from '../constants/urlConstants';
import CreateNewDocument from '../containers/Documents_new/createDocument';
import AccidentRoutes from './routes/accidentRoutes';
import InspectionRoutes from './routes/inspectionRoutes';
import AssetRoutes from './routes/assetsRoutes';
import ComplianceRoutes from './routes/complianceRoutes';
import VaultRoutes from './routes/vaultRoutes';
import TrackingLogRoutes from './routes/trackingLogRoutes'
import AssetLocationRoutes from './routes/AssetLocationRoute';
import ReimbursementRoutes from './routes/reimbursementRoutes';
import InventoryTrackersRoutes from './routes/inventoryTrackersRoutes';
import DispatchedTripRoutes from './routes/dispatchedTripRoutes';


class Routing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: JSON.parse(localStorage.getItem('menuAccess')),
    };
  }

  Update = () => {
    this.props.switchHeader();
  };
  
  renderInspectionRoutes = (access) => (
    <Route
      path={APP_URLS.INSPECTION.HOME}
      render={(routeProps) => (
        <InspectionRoutes {...routeProps} access={access.inspectionMenu} />
      )}
    />
  );
  renderAssetRoutes = (access) => (
    <Route
      path={APP_URLS.ASSETS.HOME}
      render={(routeProps) => (
        <AssetRoutes {...routeProps} access={access.assetsMenu} />
      )}
    />
  );

  renderAccidentRoutes = (access) => (
    <Route
      path={APP_URLS.ACCIDENTS.HOME}
      render={(routeProps) => (
        <AccidentRoutes {...routeProps} access={access.accidentMenu} />
      )}
    />
  );

  renderDispatchedTripRoutes = (access) => (
    <Route
      path={APP_URLS.DISPATCHED_TRIPS.HOME}
      render={(routeProps) => (
        <DispatchedTripRoutes {...routeProps} access={access.tripMenu} />
      )}
    />
  );
  
  renderComplianceRoutes = (access) => (
    <Route
      path={APP_URLS.COMPLIANCE.HOME}
      render={(routeProps) => (
        <ComplianceRoutes {...routeProps} access={access.complianceMenu} />
      )}
    />
  );

  
  renderTrackingLogRoutes = (access) => (
    <Route
      path={APP_URLS.TRACKING_LOG.HOME}
      render={(routeProps) => (
        <TrackingLogRoutes {...routeProps} access={access.logTrackingMenu} />
      )}
    />
  );
  
  renderVaultRoutes = (access) => (
    <Route
      path={APP_URLS.VAULT.HOME}
      render={(routeProps) => (
        <VaultRoutes {...routeProps} access={access.vaultMenu} />
      )}
    />
  );

  renderAssetLocationRoutes = (access) => (
    <Route
      path={APP_URLS.ASSETS_LOCATION.HOME}
      render={(routeProps) => (
        <AssetLocationRoutes {...routeProps} access={access.assetLocationMenu} />
      )}
    />
  );
  renderReimbursementRoutes = (access) => (
    <Route
      path={APP_URLS.REIMBURSEMENTS.HOME}
      render={(routeProps) => (
        <ReimbursementRoutes {...routeProps} access={access.assetLocationMenu} />
      )}
    />
  )
  
  renderInventoryTrackerRoutes = (access) => {
    return <Route
      path={APP_URLS.INVENTORY_TRACKERS.HOME}
      render={(routeProps) => (
        <InventoryTrackersRoutes {...routeProps} access={access.inventoryTrackerMenu} />
      )}
    />
  };

  //
  
  render() {
    const { access } = this.state;
    return (
      <Switch>
        <Route exact path="/" render={() => <Login callBack={this.Update} />} />
        <Route
         exact path = {APP_URLS.DOCUMENT.ADD_DOCUMENT}
         render = { routeProps => (<CreateNewDocument {...routeProps} />) }/>

        <Route
         exact path = {APP_URLS.DOCUMENT.EDIT_DOCUMENT}
         render = { routeProps => (<CreateNewDocument {...routeProps} isEdit={true} />) }/>

        <Route path="/Dashboard" component={Dashboard} />

        {access && access.customerMenu && access.customerMenu.read === true ? (
          <Route
            path="/customers/customer-and-brokers"
            component={CustomerAndBrokers}
          />
        ) : null}
        {access &&
        access.customerMenu &&
        access.customerMenu.create === true ? (
          <Route
            path="/customers/customer/add-new"
            component={CreateCustomer}
          />
        ) : null}
        <Route exact path="/customers/customer/:id/edit" component={CreateCustomer} />
        <Route path="/fleet/ordertrips/:id/view" component={OrderTrips} />

        <Route path="/UserProfile" component={Profile} />
        {access && access.orderMenu && access.orderMenu.read === true ? (
          <Route path="/fleet/orders" component={Orders} />
        ) : null}
        {access && access.orderMenu && access.orderMenu.create === true ? (
          <Route path="/fleet/order/add-new" component={CreateOrders} />
        ) : null}
        <Route path="/fleet/order/:id/edit" component={CreateOrders} />
        {access && access.tripMenu && access.tripMenu.read === true ? (
          <Route path="/fleet/trips" component={Trips} />
        ) : null}
        {access && access.tripMenu && access.tripMenu.create === true ? (
          <Route path="/fleet/trip/add-new" component={CreateTrips} />
        ) : null}
        <Route path="/fleet/trip/:id/edit" component={CreateTrips} />

        <Route path="/customers/invoices" component={Invoices} />
        {this.renderInspectionRoutes(access)}
        {this.renderAccidentRoutes(access)}
        {this.renderDispatchedTripRoutes(access)}
        {this.renderAssetRoutes(access)}
        {this.renderAssetRoutes(access)}
        {this.renderComplianceRoutes(access)}
        {this.renderVaultRoutes(access)}
        {this.renderAssetLocationRoutes((access))}
        {this.renderReimbursementRoutes(access)}
        {this.renderInventoryTrackerRoutes(access)}
        <Route path="/Reimbursement/add/:id" component={CreateReimbursement} />
        <Route path="/Reimbursement/:id/edit" component={CreateReimbursement} />
        <Route path={APP_URLS.EMPLOYEES.ADD_EMPLOYEES} component={CreateEmployee} />
        <Route path={APP_URLS.EMPLOYEES.EDIT_EMPLOYEES} component={CreateEmployee} />  
        <Route path={APP_URLS.EMPLOYEES.HOME} component={Employees} />
        <Route path={APP_URLS.DOCUMENT.HOME} 
         render = {routeProps => <DocumentNew  {...routeProps} screenPermissions={access.documentMenu}/> } />
        <Route path="/BorderConnect/" component={AddBorderConnect} />
        <Route path="*" render={() => <NotFound />} />
      </Switch>
    );
  }
}

export default Routing;
