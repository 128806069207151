import React from 'react';
import { Route } from 'react-router-dom';

import DispatchedTripsScreen from '../../containers/DispatchedTripsScreen';
// import CreateDispatchedTripContainer from '../../containers/DispatchedTripsScreen/CreateDispatchedTrip';
import { APP_URLS } from '../../constants/urlConstants';

const DispatchedTripRoutes = (props) => {
  const { access, match } = props;
  const { create, read, update } = access;

  return (
    <>
      {!!read && (
        <Route
          exact
          path={match.path}
          render={(routeProps) => (
            <DispatchedTripsScreen {...routeProps} screenPermissions={access} />
          )}
        />
      )}
      {/* {!!create && (
        <Route
          path={APP_URLS.DISPATCHED_TRIPS.ADD_DISPATCHED_TRIP}
          component={CreateDispatchedTripContainer}
        />
      )}
      {!!update && (
        <Route
          path={APP_URLS.DISPATCHED_TRIPS.EDIT_DISPATCHED_TRIP}
          render={(routeProps) => (
            <CreateDispatchedTripContainer {...routeProps} isEdit={true} />
          )}
        />
      )} */}
    </>
  );
};

export default DispatchedTripRoutes;
