import { handleActions } from 'redux-actions'
import {
  fetchTripsStart,
  fetchTripsComplete
} from '../../Actions/Dropdown/action.plain'

export const  reimbursementDropDownReducer =  handleActions(
  {
    [fetchTripsStart] : (state) => ({ ...state, tripsIsLoading : true }),
    [fetchTripsComplete] : (state, {payload : { trips }}) => ({ ...state, trips })
  },
  { tripsIsLoading : null, trips: [] }
  )
  
  
      