import { useEffect } from "react"
import { connect } from "react-redux";
import CreateReimbursement from "../../../components/Reimbursement_new/CreateReimbursement";
import {
        selectformFieldValueMap,
        selectIsCreateReimbursementsError,
        selectIsCreateReimbursementsSuccess,
        selectIsReimbursementDetailError,
        selectIsReimbursementDetailSuccess,
        selectIsEditReimbursementError,
        selectIsEditReimbursementSuccess } from "../../../Redux/Selectors/Reimbursement_new";
import { fetchReimbursementByReimbursementId,
        createReimbursementRecord,
        editReimbursementRecord,
        fetchTrips } from "../../../Redux/Actions/reimbursement_new";
import { API_URL, APP_URLS } from "../../../constants/urlConstants";
import { setIsformDirty } from "../../../Redux/Actions/common/form";
import { getEmployeesdropDown, getTripsList } from "../../../Redux/Selectors/dropDown";
import { fetchTypes } from "../../../Redux/Actions/types";
import { getReimbursementStatusList, getTaxTypesList } from "../../../Redux/Selectors/types";
import { createLabelValueObj } from "../../../common/ContextMenu/utils";
import { tripTypesOptions } from "../../../constants/action-types/common/options";
import { fetchDriver } from "../../../Redux/Actions/assets/assetTracker";
import { FILTER } from "../../../Services/common/urlService";

const { Manual, ExistingTrips } = tripTypesOptions

const CreateReimbursementContainer = props =>{
    
    const {
            isEdit,
            history,
            fetchReimbursementByReimbursementId,
            fetchTrips,
            fetchTypes,
            fetchDriver
         } = props;
    
    const { id: reimbursementId } = props?.match?.params;
    console.log(reimbursementId, 'reimbursementId'); 

    useEffect(()=> {
      if (isEdit && reimbursementId) {
       fetchReimbursementByReimbursementId({ reimbursementId });
      }
      fetchTrips();
      fetchTypes({params : API_URL.TYPES.TAX_TYPE })
      fetchTypes({params : API_URL.TYPES.REIMB_STATUS })
      fetchDriver()
    },[fetchReimbursementByReimbursementId, fetchTrips, fetchTypes, isEdit, reimbursementId, fetchDriver]);

    const searchDrivers = ({ query, filterField }) => {
      if (query) {
        fetchDriver({
          shouldPaginate: false,
          filters: [{ filterField, query, matchStrategy: FILTER.MATCH_STRATEGY.CONTAINS }],
        });
      } else {
        fetchDriver();
      }
    };
    
    const navigateToReimbursementSummaryScreen = () => {
        history.push(APP_URLS.REIMBURSEMENTS.HOME);
    }
    
    const tripTypes = [ 
                        createLabelValueObj(Manual, Manual),
                        createLabelValueObj(ExistingTrips, ExistingTrips) 
                      ];
    
    const createReimbursementProps = {
      ...props,
      navigateToReimbursementSummaryScreen,
      reimbursementId,
      tripTypes,
      searchDrivers
    }
    
    return (
            <>
                <CreateReimbursement {...createReimbursementProps} />
            </> 
        );
}

const mapStateToProps = (state,onwProps) =>{
    return {
    ...onwProps,
    isCreateReimbursementError: selectIsCreateReimbursementsError(state),
    isCreateReimbursementSuccess: selectIsCreateReimbursementsSuccess(state),
    formFieldValueMap: selectformFieldValueMap(state),
    isReimbursementDetailSuccess: selectIsReimbursementDetailSuccess(state),
    isReimbursementDetailError: selectIsReimbursementDetailError(state),
    isEditReimbursementSuccess: selectIsEditReimbursementSuccess(state),
    isEditReimbursementError: selectIsEditReimbursementError(state),
    trips: getTripsList(state),
    taxTypes: getTaxTypesList(state),
    reimbursementStatusList :  getReimbursementStatusList(state),
    drivers: getEmployeesdropDown(state),    
 }
}

const mapdispatchToProps = dispatch => {
    return {
        createReimbursement: payload => dispatch(createReimbursementRecord(payload)),
        editReimbursement: payload=> dispatch(editReimbursementRecord(payload)),
        fetchReimbursementByReimbursementId: ({reimbursementId}) => dispatch(fetchReimbursementByReimbursementId({reimbursementId})),
        setIsformDirty: isFormDirty => dispatch(setIsformDirty(isFormDirty)),
        fetchTrips: () => dispatch(fetchTrips()),
        fetchTypes: (payload) => dispatch(fetchTypes(payload)),
        fetchDriver: payload => dispatch(fetchDriver(payload))
    }
}

export default connect(mapStateToProps, mapdispatchToProps)(CreateReimbursementContainer);
