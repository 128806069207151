/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row, Container } from 'react-bootstrap';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { BreadCrumb } from 'primereact/breadcrumb';
import EzControlledDropDowntWithFloatingLabel from "../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import EzControlledInputWithFloatingLabel from '../../../common/EzForm/EzControlledInput/WithFloatingLabel';
import EzControlledCalendarWithFloatingLabel from '../../../common/EzForm/EzControlledCalendar/WithFloatingLabel';
import EzFileUpload from '../../../common/EzFileUpload';
import './index.css';
import { EzToast, TOAST_SEVERITY } from '../../../common/EzToast';
import { tripTypesOptions } from '../../../constants/action-types/common/options';
import EzControlledAutoSuggestWithFloatingLabel from '../../../common/EzForm/EzControlledAutoSuggest/WithFloatingLabel';

const transFormToDriver = ({ first, last, id , ...extraData })=>  ({
  label : `${first} ${last}`,
  value : id,
  extraData
})

const createDriverList = tripDetails => {
  if(tripDetails?.length === 0) return [];
  const {driver1, driver2} = tripDetails[0].extraData;
  return [transFormToDriver(driver1), transFormToDriver(driver2)];
}

const getValidationRule = () => {
  return {
    DEFAULT: {
      DO_NOT_VALIDATE: {},
      VALIDATION_RULE: { required: true },
      VALIDATION_RULE_MESSAGE: { required: 'This is required field' },
    },
    TRIP: {required: true},
    REIMB_STATUS: {required: true},
    DATE: { required: true },
    PAID_TO: {required: true},
    TOTAL_AMOUNT: {required: true},
    AMOUNT_WO_TAX: {required: true}
  };
};

const CreateReimbursement = (props) => {
  
  const {
    formFieldValueMap,
    navigateToReimbursementSummaryScreen,
    isCreateReimbursementSuccess,
    isEditReimbursementSuccess,
    isEdit,
    isReimbursementDetailSuccess,
    setIsformDirty,
    trips,
    taxTypes,
    tripTypes,
    reimbursementStatusList,
    drivers,
    searchDrivers,
    createReimbursement,
    reimbursementId,
    editReimbursement
  } = props
  
  const [attachedFiles, setAttachedFiles] = useState([]);
  const removedFiles = [];
  const onSubmit = (data) => {
    delete data.files;
    const { trip,
            driver_id,
            reimb_date,
            paid_to,
            ttl_amt,
            tax,
            hst_amt,
            reimb_status,
            trip_type,
            manual_trip
      } = data;
    
    const totalAmount = parseFloat(ttl_amt, 10);
    const hstAmount = parseFloat(hst_amt, 10);
    const txnAmount = parseFloat((totalAmount - hstAmount).toPrecision(6));
    const modifiedDriverId = tripTypeSelected === tripTypesOptions.Manual ? 
      (driver_id?.extraData?.id ?? getDriverId(driver_id))  : driver_id; 
    
    const manualTripType = trip_type === tripTypesOptions.Manual;

    const dataPayload = {
      files: removedFiles,
      trip: manualTripType ? null : trip,
      trip_id: modifiedDriverId,
      driver_id: modifiedDriverId,
      reimb_date: new Date(reimb_date).getTime(),
      paid_to,
      ttl_amt: totalAmount,
      tax,
      txn_amt: txnAmount,
      hst_amt,
      reimb_status,
      trip_type: manualTripType ? true: false,
      manual_trip : manualTripType ? manual_trip : null
    } 
      if (isEdit) {
      editReimbursement({
        reimbursementId,
        data: dataPayload,
        ...(attachedFiles ? { files: attachedFiles } : {}),
      });
    } else {
      createReimbursement({
        data: dataPayload,
        files: attachedFiles,
      });
   }
  };
  
  const getDriverName = (id) => drivers.filter( driver => driver.extraData.id === id)[0]?.label
  const getDriverId = (name) => drivers.filter( driver => driver.label === name)[0]?.extraData?.id
  const {
    control,
    formState: { errors, dirtyFields},
    watch,
    handleSubmit,
    reset,
    resetField,
    setValue
  } = useForm({defaultValues:useMemo(()=>formFieldValueMap, [formFieldValueMap])});
  
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  useEffect(() => {
    if (isEditReimbursementSuccess || isCreateReimbursementSuccess) {
      reset();
    }
  }, [isCreateReimbursementSuccess,isEditReimbursementSuccess]);
  useEffect(() => {
    if (isReimbursementDetailSuccess) {
      reset({
        ...formFieldValueMap,
        tax: formFieldValueMap.tax.id,
        trip_type: formFieldValueMap.trip_type ? tripTypesOptions.Manual : tripTypesOptions.ExistingTrips,
        trip: formFieldValueMap?.trip?.id,
        reimb_status: formFieldValueMap?.reimb_status?.id,
        driver_id: formFieldValueMap.trip_type ?  getDriverName(formFieldValueMap.driver_id) : formFieldValueMap.driver_id
      });
      setAttachedFiles(formFieldValueMap?.files); 
    }
  }, [isReimbursementDetailSuccess]);

  const isDirty = Object.keys(dirtyFields).length > 0;
  useEffect(()=> setIsformDirty(isDirty),[isDirty]);

  const [showInputTripText, setShowInputTripText] = useState(false);

  const tripTypeSelected = watch('trip_type');
  const taxSelected = watch('tax');
  const totalAmount = watch('ttl_amt');
  const tripSelected = watch('trip') || watch('manual_trip');
  const [driverList, setDriverList] = useState();

  useEffect( ()=> {
    const taxPercent = parseFloat(taxTypes?.filter( tax => tax.value === taxSelected)[0]?.extraData?.lblval?.val);
    const amountWithoutTax = parseFloat(totalAmount) - (taxPercent*totalAmount)/100;
    isNaN(amountWithoutTax) ? setValue('hst_amt', 0 ) : setValue('hst_amt', amountWithoutTax ) 
  },[taxSelected, totalAmount]);

  useEffect( () => {
    if(tripTypeSelected === tripTypesOptions.Manual) setShowInputTripText(true);
    else setShowInputTripText(false);
    resetField('trip', '')
    resetField('manual_trip', '')
    resetField('driver_id', '')
  },[tripTypeSelected]) 
    
  useEffect( () => {
    if(tripTypeSelected === tripTypesOptions.ExistingTrips){
      const tripDetails = trips?.filter( trip => trip.value === tripSelected) || []
      setDriverList(createDriverList(tripDetails))
    }
  },[tripSelected]) 
  
  const [showErrorToast, setErrorToast] = useState(false);
  const getToastProps = () => {
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle: 'Please fill all mandatory fields',
      shouldShowToast: true,
    };
  };

  useEffect(() => {
    if (Object.keys(errors).length) {
      setErrorToast(true);
    }
  }, [errors]);

  const renderPageHeaderSection = () => {
    const breadCrumbs = [
      { label: 'Reimbursement' },
      { label: isEdit ? 'Edit Reimbursement' : 'Create Reimbursement' },
    ];

    const breadcrumbHome = { icon: 'pi pi-home', url: '/' };

    return (
      <div className="make-relative">
        <BreadCrumb
          style={{ width: '50%', border: 'none' }}
          model={breadCrumbs}
          home={breadcrumbHome}
        />
        <div className="rightbtn">
          <Button
            tooltip={'Back to reimbursement summary'}
            tooltipOptions={{ position: 'left' }}
            label={''}
            icon={'pi pi-arrow-left'}
            className={`mr-3 p-button-rounded p-button-outlined p-button-info`}
            onClick={navigateToReimbursementSummaryScreen}
          />
        </div>
      </div>
    );
  };

  const renderTripTypes   = () => {
    const name = 'trip_type';
    
    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={getValidationRule().DEFAULT.VALIDATION_RULE}
        options={tripTypes}
        onChange={(e) => console.log(e)}
        isFloatingLabel={true}
        isControlled={true}
        optionLabel = {'label'}
        optionValue = {'value'} 
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Trip Type"
        labelClassName={'ml-2'}
      />
    );
  };

  const renderTrip = () => {
    if(showInputTripText) {
      const name = 'manual_trip';
      return <EzControlledInputWithFloatingLabel
              name={name}
              control={control}
              label={'Trip'}
              rules={getValidationRule().DEFAULT.VALIDATION_RULE}
              isError={errors[name]}
              errorMsg={getFormErrorMessage(name)}
            />
    };
    const name ='trip'
    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={getValidationRule().TRIP}
        options={trips}
        onChange={(e) => console.log(e)}
        isFloatingLabel={true}
        isControlled={true}
        optionLabel = {'label'}
        optionValue = {'value'} 
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Trip"
        labelClassName={'ml-2'}
      />
    );
  };
  
  const renderStatus = () => {
    const name = 'reimb_status';
    
    return (
      <EzControlledDropDowntWithFloatingLabel
      name={name}
      control={control}
      rules={getValidationRule().DEFAULT.VALIDATION_RULE}
      options={reimbursementStatusList}
      onChange={(e) => console.log(e)}
      isFloatingLabel={true}
      isControlled={true}
      optionLabel = {'label'}
      optionValue = {'value'} 
      isError={errors[name]}
      errorMsg={getFormErrorMessage(name)}
      label="Status"
      labelClassName={'ml-2'}
      />
    );
  };
  const renderDriver = () => {
    const name = 'driver_id';
    
    if(tripTypeSelected === tripTypesOptions.Manual)
    {
      return (
        <EzControlledAutoSuggestWithFloatingLabel
            name={name}
            control={control}
            suggestions={drivers}
            rules={getValidationRule().DEFAULT.VALIDATION_RULE}
            completeMethod={({ query }) => searchDrivers({ query, filterField: 'first' })}
            label={`select Driver`}
            isError={errors[name]}
            errorMsg={getFormErrorMessage(name)}
        />
      )
    }

    return (
      <EzControlledDropDowntWithFloatingLabel
        name={name}
        control={control}
        rules={getValidationRule().DEFAULT.VALIDATION_RULE}
        options={driverList}
        onChange={(e) => console.log(e)}
        isFloatingLabel={true}
        isControlled={true}
        optionLabel = {'label'}
        optionValue = {'value'} 
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label="Driver"
        labelClassName={'ml-2'}
      />
    )
  };  

  const renderTax = () => {
    const name = 'tax';
    
    return (
      <EzControlledDropDowntWithFloatingLabel
      name={name}
      control={control}
      rules={getValidationRule().DEFAULT.VALIDATION_RULE}
      options={taxTypes}
      onChange={(e) => console.log(e)}
      isFloatingLabel={true}
      isControlled={true}
      optionLabel = {'label'}
      optionValue = {'value'} 
      isError={errors[name]}
      errorMsg={getFormErrorMessage(name)}
      label="Tax"
      labelClassName={'ml-2'}
      />
    );
  };

  const renderDate = () => {
    const name = 'reimb_date';

    return (
      <EzControlledCalendarWithFloatingLabel
        name={name}
        showTime={true}
        rules={getValidationRule().DATE}
        control={control}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
        label={'Date'}
      />
    );
  };

  const renderPaidTo = () => {
    const name = 'paid_to';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Paid To'}
        rules={getValidationRule().PAID_TO}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderTotalAmount = () => {
    const name = 'ttl_amt';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Total Amount'}
        //rules={getValidationRule().TOTAL_AMOUNT}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderAmountWithoutTax = () => {
    const name = 'hst_amt';

    return (
      <EzControlledInputWithFloatingLabel
        disabled = {true}
        name={name}
        control={control}
        label={'Amount W/O tax'}
        //rules={getValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };
  
  const renderFileUploader = () => {
    const onSelectFile = (e, field) => {
      setAttachedFiles([...e.files]);
    };

    const onRemoveFile = (fileTobeRemoved) => {
      removedFiles.push(fileTobeRemoved)
    }
    
    return (
      <EzFileUpload
        files={attachedFiles}
        onSelectFile={onSelectFile}
        onRemoveFile={onRemoveFile}
      />
    );
  };

  return (
    <> 
    <div className="paddingbox">
      <div
        className={classNames('containerboxform', { 'p-disabled': false })}
      >
        {renderPageHeaderSection()}
        <div
          className="form-demo"
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
        >
          <div className="card" style={{ background: '#f3faf4BF' }}>
            {showErrorToast && <EzToast {...getToastProps() } />}
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <Container fluid>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderDate()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderTripTypes()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderTrip()}</Col>
                </Row>
                <br></br>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderTotalAmount()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderTax()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderAmountWithoutTax()}</Col>
                </Row>
                <br></br>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderStatus()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderPaidTo()}</Col>
                  <Col md="1" />
                  <Col md="3">{tripSelected &&  renderDriver()}</Col>
                </Row>
                <br></br>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3"></Col>
                  <Col md="1" />
                  <Col md="7">{renderFileUploader()}</Col>
                </Row>
              </Container>
              <div
                className={'make-flex mt-3 mr-2'}
                style={{ flexDirection: 'row-reverse', background: 'white' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      label="Cancel"
                      onClick={navigateToReimbursementSummaryScreen}
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      type="reset"
                      label="Reset"
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '170px' }}>
                    <Button
                      loading={false}
                      icon={'pi pi-plus'}
                      type="submit"
                      label={isEdit ? 'Edit Reimbursement' : 'Create Reimbursement'}
                      className="mt-2 p-button-raised"
                    />    
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default CreateReimbursement;
