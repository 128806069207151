export const INIT_DISPATCHED_TRIPS = 'INIT_DISPATCHED_TRIPS';
export const FETCH_DISPATCHED_TRIPS_LIST_START = 'FETCH_DISPATCHED_TRIPS_LIST_START';
export const FETCH_DISPATCHED_TRIPS_LIST_SUCCESS = 'FETCH_DISPATCHED_TRIPS_LIST_SUCCESS';
export const FETCH_DISPATCHED_TRIPS_LIST_ERROR = 'FETCH_DISPATCHED_TRIPS_LIST_ERROR';

export const DELETE_DISPATCHED_TRIP_RECORD_START = 'DELETE_DISPATCHED_TRIP_RECORD_START';
export const DELETE_DISPATCHED_TRIP_RECORD_SUCCESS = 'DELETE_DISPATCHED_TRIP_RECORD_SUCCESS';
export const DELETE_DISPATCHED_TRIP_RECORD_ERROR = 'DELETE_DISPATCHED_TRIP_RECORD_ERROR';

export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';

export const INIT_CREATE_NEW_DISPATCHED_TRIP_SCREEN =
  'INIT_CREATE_NEW_DISPATCHED_TRIP_SCREEN';

export const CREATE_DISPATCHED_TRIP_RECORD = {
  START: 'CREATE_DISPATCHED_TRIP_RECORD_START',
  SUCCESS: 'CREATE_DISPATCHED_TRIP_RECORD_SUCCESS',
  ERROR: 'CREATE_DISPATCHED_TRIP_RECORD_ERROR',
};

export const EDIT_DISPATCHED_TRIP_RECORD = {
  START: 'EDIT_DISPATCHED_TRIP_RECORD_START',
  SUCCESS: 'EDIT_DISPATCHED_TRIP_RECORD_SUCCESS',
  ERROR: 'EDIT_DISPATCHED_TRIP_RECORD_ERROR',
};

export const FETCH_DISPATCHED_TRIP_DETAIL = {
  START: 'FETCH_DISPATCHED_TRIP_DETAIL_START',
  SUCCESS: 'FETCH_DISPATCHED_TRIP_DETAIL_SUCCESS',
  ERROR: 'FETCH_DISPATCHED_TRIP_DETAIL_ERROR',
};


export const TOAST = {
  RESET: 'RESET_TOAST',
};
