  import { 
        FETCH_REIMBURSEMENT_DETAIL, INIT_REIMBURSEMENTS,
        FETCH_REIMBURSEMENTS_LIST_START,
        FETCH_REIMBURSEMENTS_LIST_SUCCESS, 
        FETCH_REIMBURSEMENTS_LIST_ERROR, 
        SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
        DELETE_REIMBURSEMENT_RECORD_START,
        DELETE_REIMBURSEMENT_RECORD_SUCCESS,
        DELETE_REIMBURSEMENT_RECORD_ERROR,
        CREATE_REIMBURSEMENT_RECORD,
        TOAST,
        EDIT_REIMBURSEMENT_RECORD} from "../../../constants/action-types/Reimbursement";
import  * as reimbursement_newService  from '../../../Services/business/Reimbursement_newService';
import { DEFAULT_TOAST_LIFE } from '../../../common/EzToast';
import {
  attachmentsFilesFetchStart,
  attachmentsFilesFetchSuccess,
  downloadingAllAttachmentComplete,
  downloadingAllAttachmentStart
 } from "../common/files";
import { fetchTripsComplete, fetchTripsStart } from "../Dropdown/action.plain";

export const initializeReimbursementsScreen = () => ({ type: INIT_REIMBURSEMENTS });

export const reimbursementsFetchStart = (payload) => ({
    type: FETCH_REIMBURSEMENTS_LIST_START,
    payload,
  });
  
  export const reimbursementsFetchSuccess = (payload) => ({
    type: FETCH_REIMBURSEMENTS_LIST_SUCCESS,
    payload,
  });
  
  export const reimbursementsFetchError = (payload) => ({
    type: FETCH_REIMBURSEMENTS_LIST_ERROR,
    payload,
  });
  

export const fetchReimbursements = (payload) => dispatch => {
      dispatch(reimbursementsFetchStart(payload));
      reimbursement_newService.fetchReimbursements(payload).then((reimbursementsData) => {
        if (!reimbursementsData.isError) {
          dispatch(reimbursementsFetchSuccess(reimbursementsData));
        } else {
          dispatch(reimbursementsFetchError(reimbursementsData));
        }
      });
};

  export const setDeleteConfirmationPopupVisibility = (payload) => ({
    type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
    payload,
  });


  export const deleteReimbursementRecordStart = () => ({
    type: DELETE_REIMBURSEMENT_RECORD_START,
  });
  
  export const deleteReimbursementRecordSuccess = (payload) => ({
    type: DELETE_REIMBURSEMENT_RECORD_SUCCESS,
    payload,
  });
  
  export const deleteReimbursementRecordError = (payload) => ({
    type: DELETE_REIMBURSEMENT_RECORD_ERROR,
    payload,
  });
  

export const fetchFilesByReimbursementId = payload => async (dispatch, getstate) => {
  dispatch(attachmentsFilesFetchStart(payload));
  const {files} = await reimbursement_newService.fetchReimbursementsAttachmentByReimbursementId(payload);
  if(files) dispatch(attachmentsFilesFetchSuccess(files));
}
 
export const downloadAllAttachmentForReimbursementId =  (payload) =>  async (dispatch) => {
  dispatch(downloadingAllAttachmentStart())
  await reimbursement_newService.downloadReimbursementsAllAttachment(payload);
  dispatch(downloadingAllAttachmentComplete());
}

export const deleteReimbursementRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteReimbursementRecordStart());
    reimbursement_newService
      .deleteReimbursementRecord({ reimbursementId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteReimbursementRecordSuccess());
          dispatch(fetchReimbursements(payload?.params));
        } else {
          dispatch(deleteReimbursementRecordError(data));
        }
      });
  };
};

export const initCreateNewReimbursementScreen = () => {
  return (dispatch) => {
    
  };
};

export const createReimbursementRecordStart = () => ({
  type: CREATE_REIMBURSEMENT_RECORD.START,
});

export const createReimbursementRecordSuccess = (payload) => ({
  type: CREATE_REIMBURSEMENT_RECORD.SUCCESS,
  payload,
});

export const createReimbursementRecordError = (payload) => ({
  type: CREATE_REIMBURSEMENT_RECORD.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createReimbursementRecord = (payload) => {
  return (dispatch) => {
    dispatch(createReimbursementRecordStart());
    reimbursement_newService.createReimbursementRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(createReimbursementRecordSuccess());
      } else {
        dispatch(createReimbursementRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const reimbursementDetailFetchStart = (payload) => { 
  return {
  type: FETCH_REIMBURSEMENT_DETAIL.START,
  payload,
}}

export const reimbursementDetailFetchSuccess = (payload) => ({
  type: FETCH_REIMBURSEMENT_DETAIL.SUCCESS,
  payload,
});

export const reimbursementDetailFetchError = (payload) => ({
  type: FETCH_REIMBURSEMENT_DETAIL.ERROR,
  payload,
});

export const fetchReimbursementByReimbursementId = (payload) => {
  return async (dispatch) => {
    dispatch(reimbursementDetailFetchStart(payload));

    let reimbursementData = await reimbursement_newService.fetchReimbursementByReimbursementId(payload);
      if (!reimbursementData.isError) {
        dispatch(reimbursementDetailFetchSuccess(reimbursementData));
      } else {
        dispatch(reimbursementDetailFetchError(reimbursementData));
      } 
  };
};

export const editReimbursementRecordStart = () => ({
  type: EDIT_REIMBURSEMENT_RECORD.START,
});

export const editReimbursementRecordSuccess = (payload) => ({
  type: EDIT_REIMBURSEMENT_RECORD.SUCCESS,
  payload,
});

export const editReimbursementRecordError = (payload) => ({
  type: EDIT_REIMBURSEMENT_RECORD.ERROR,
  payload,
});

export const  editReimbursementRecord = (payload) => {
  return (dispatch) => {
    dispatch(editReimbursementRecordStart());
    reimbursement_newService.editReimbursementRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(editReimbursementRecordSuccess());
      } else {
        dispatch(editReimbursementRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };  
};
export const fetchTrips = (payload) => {
  return async (dispatch) => {
    dispatch(fetchTripsStart())
    const {content : trips} =  await reimbursement_newService.fetchTrips(payload);
    dispatch(fetchTripsComplete({trips}))
  };
};

