import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';

import DispatchedTrip from '../../components/DispatchedTrips';
import { loadingsShow } from '../../Services/PostAPI';
import {
  initializeDispatchedTripScreen,
  fetchDispatchedTrips,
  deleteDispatchedTripRecord,
  setDeleteConfirmationPopupVisibility,
  downloadAllAttachmentByDispatchedTripId,
  fetchFilesByDispatchedTripId,
} from '../../Redux/Actions/dispatchedTrips';
import { APP_URLS } from '../../constants/urlConstants';
import { DISPATCHED_TRIP_DATE_DATA_KEY } from '../../Redux/Reducers/dispatchedTrips';


const DispatchedTripsScreen = (props) => {
  const {
    dispatchedTripsListView,
    fetchDispatchedTrips,
    history,
    initDispatchedTripScreen,
    deleteDispatchedTripRecord,
    isFetchingGridData,
    paginationInfo,
    showPageLevelLoader,
    screenPermissions,
    isDispatchedTripDeletionError,
    isDispatchedTripDeletionSuccess,
    isPageLevelError,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    downloadAllAttachmentByDispatchedTripId,
    fetchDispatchedTripAttachmentsByDispatchedTripId,
  } = props;

  
  useEffect(() => {
    initDispatchedTripScreen();
    fetchDispatchedTrips({ sortField: DISPATCHED_TRIP_DATE_DATA_KEY });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editDispatchedTripRecord = (dispatchedTripId) => {
    const path = generatePath(APP_URLS.DISPATCHED_TRIPS.EDIT_DISPATCHED_TRIP, { dispatchedTripId });
    history.push(path);
  };

  const createDispatchedTripRecord = () => {
    history.push(APP_URLS.DISPATCHED_TRIPS.ADD_DISPATCHED_TRIP);
  };

  const moreActionProps = {
        isMoreActionDisabled : false,
        downloadEnabled :true,
        moreActionOptions : [],
        onMoreActionChange : null
  }

  const dispatchedTripProps = {
    dispatchedTripsListView,
    createDispatchedTripRecord,
    fetchDispatchedTrips,
    deleteDispatchedTripRecord,
    editDispatchedTripRecord,
    isFetchingGridData,
    paginationInfo,
    screenPermissions,
    isDispatchedTripDeletionError,
    isDispatchedTripDeletionSuccess,
    isPageLevelError,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    downloadAllAttachmentByDispatchedTripId,
    fetchDispatchedTripAttachmentsByDispatchedTripId,
    moreActionProps
  };
  
  if (showPageLevelLoader) {
    loadingsShow('block');
    return null;
  }
  loadingsShow('none');
  return (
    <>
      <DispatchedTrip {...dispatchedTripProps} />;
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initDispatchedTripScreen: () => dispatch(initializeDispatchedTripScreen()),
    fetchDispatchedTrips: (payload) => dispatch(fetchDispatchedTrips(payload)),
    deleteDispatchedTripRecord: (payload) => dispatch(deleteDispatchedTripRecord(payload)),
    setDeleteConfirmationPopupVisibility: (payload) => dispatch(setDeleteConfirmationPopupVisibility(payload)),
    downloadAllAttachmentByDispatchedTripId : (payload) => dispatch(downloadAllAttachmentByDispatchedTripId(payload)),
    fetchDispatchedTripAttachmentsByDispatchedTripId: payload =>  dispatch(fetchFilesByDispatchedTripId(payload))
  };
};

// TODO(Subham):  This selection of data needs improvement, See if we can use UseSelector here.
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    dispatchedTripsListView: selectDispatchedTripsList(state.dispatchedTripsReducer),
    isFetchingGridData: selectIsLoading(state.dispatchedTripsReducer),
    showPageLevelLoader: selectIsLoadingPage(state.dispatchedTripsReducer),
    paginationInfo: selectPaginationInfo(state.dispatchedTripsReducer),
    isPageLevelError: selectIsPageLevelError(state.dispatchedTripsReducer),
    isDispatchedTripDeletionError: selectIsDispatchedTripDeletionError(
      state.dispatchedTripsReducer
    ),
    isDispatchedTripDeletionSuccess: selectIsDispatchedTripDeletionSuccess(
      state.dispatchedTripsReducer
    ),
    shouldOpenDeleteConfirmationPopup: selectShouldOpenDeleteConfirmationPopup(
      state.dispatchedTripsReducer
    ),
    files:selectAttachmentFiles(state.dispatchedTripsReducer)
  };
};

const selectIsPageLevelError = (dispatchedTripReducer) =>
  dispatchedTripReducer.isPageLevelError;
const selectIsDispatchedTripDeletionError = (dispatchedTripReducer) =>
  dispatchedTripReducer.isDispatchedTripDeletionError;
const selectIsDispatchedTripDeletionSuccess = (dispatchedTripReducer) =>
  dispatchedTripReducer.isDispatchedTripDeletionSuccess;
const selectPaginationInfo = (dispatchedTripReducer) =>
  dispatchedTripReducer.paginationInfo;
const selectIsLoading = (dispatchedTripsReducer) => dispatchedTripsReducer.isLoading;
const selectIsLoadingPage = (dispatchedTripsReducer) =>
  dispatchedTripsReducer.isLoadingPage;
const selectDispatchedTripsList = (dispatchedTripsReducer) =>
  dispatchedTripsReducer.dispatchedTripsListView;
const selectShouldOpenDeleteConfirmationPopup = (dispatchedTripsReducer) =>
  dispatchedTripsReducer.shouldOpenDeleteConfirmationPopup;
export const selectAttachmentFiles = (dispatchedTripsReducer) => dispatchedTripsReducer.attachmentsfiles

export default connect(mapStateToProps, mapDispatchToProps)(DispatchedTripsScreen);
