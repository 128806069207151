import { DEFAULT_TOAST_LIFE } from '../../../common/EzToast';
import {
  INIT_DISPATCHED_TRIPS,
  FETCH_DISPATCHED_TRIPS_LIST_ERROR,
  FETCH_DISPATCHED_TRIPS_LIST_START,
  FETCH_DISPATCHED_TRIPS_LIST_SUCCESS,
  DELETE_DISPATCHED_TRIP_RECORD_START,
  DELETE_DISPATCHED_TRIP_RECORD_SUCCESS,
  DELETE_DISPATCHED_TRIP_RECORD_ERROR,
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  INIT_CREATE_NEW_DISPATCHED_TRIP_SCREEN,
  CREATE_DISPATCHED_TRIP_RECORD,
  EDIT_DISPATCHED_TRIP_RECORD,
  FETCH_DISPATCHED_TRIP_DETAIL,
  TOAST,
} from '../../../constants/action-types/dispatchedTrip';
import { dispatchedTripsService } from '../../../Services';
import { goBack } from '../../../utils/gotoback';
import { fetchEmployees } from '../employees';
import {
  attachmentsFilesFetchSuccess ,
  attachmentsFilesFetchStart,
  downloadingAllAttachmentStart,
  downloadingAllAttachmentComplete,
  attachmentsFilesFetchError,
  downloadingAllAttachmentError
} from '../common/files'

export const initializeDispatchedTripScreen = () => ({ type: INIT_DISPATCHED_TRIPS });

export const dispatchedTripsFetchStart = (payload) => ({
  type: FETCH_DISPATCHED_TRIPS_LIST_START,
  payload,
});

export const dispatchedTripsFetchSuccess = (payload) => ({
  type: FETCH_DISPATCHED_TRIPS_LIST_SUCCESS,
  payload,
});

export const dispatchedTripsFetchError = (payload) => ({
  type: FETCH_DISPATCHED_TRIPS_LIST_ERROR,
  payload,
});

export const fetchDispatchedTrips = (payload) => {
  return (dispatch) => {
    dispatch(dispatchedTripsFetchStart(payload));

    dispatchedTripsService.fetchDispatchedTrips(payload).then((dispatchedTripsData) => {
      if (!dispatchedTripsData.isError) {
        dispatch(dispatchedTripsFetchSuccess(dispatchedTripsData));
      } else {
        dispatch(dispatchedTripsFetchError(dispatchedTripsData));
      }
    });
  };
};

export const dispatchedTripDetailFetchStart = (payload) => ({
  type: FETCH_DISPATCHED_TRIP_DETAIL.START,
  payload,
});

export const dispatchedTripDetailFetchSuccess = (payload) => ({
  type: FETCH_DISPATCHED_TRIP_DETAIL.SUCCESS,
  payload,
});

export const dispatchedTripDetailFetchError = (payload) => ({
  type: FETCH_DISPATCHED_TRIP_DETAIL.ERROR,
  payload,
});

export const fetchFilesByDispatchedTripId = payload => async dispatch => {
  let request = null
  dispatch(attachmentsFilesFetchStart(payload));
  try {
    request = await dispatchedTripsService.fetchDispatchedTripAttachmentByDispatchedTripId(payload);
  }
  catch{
    dispatch(attachmentsFilesFetchError())
  }
  if(request.files){ 
    dispatch(attachmentsFilesFetchSuccess(request.files));
  }
}

export const downloadAllAttachmentByDispatchedTripId =  (payload) =>  async (dispatch) => {
  dispatch(downloadingAllAttachmentStart())
  try{
    await dispatchedTripsService.downloadAllAttachment(payload);
  }
  catch {
    dispatch(downloadingAllAttachmentError())
  }
  dispatch(downloadingAllAttachmentComplete());
}


export const fetchDispatchedTripByDispatchedTripId = (payload) => {
  return (dispatch) => {
    dispatch(dispatchedTripDetailFetchStart(payload));

    dispatchedTripsService
      .fetchDispatchedTripByDispatchedTripId(payload)
      .then((dispatchedTripsData) => {
        if (!dispatchedTripsData.isError) {
          dispatch(dispatchedTripDetailFetchSuccess(dispatchedTripsData));
        } else {
          dispatch(dispatchedTripDetailFetchError(dispatchedTripsData));
        }
      });
  };
};


// delete dispatchedTrip
export const deleteDispatchedTripRecordStart = () => ({
  type: DELETE_DISPATCHED_TRIP_RECORD_START,
});

export const deleteDispatchedTripRecordSuccess = (payload) => ({
  type: DELETE_DISPATCHED_TRIP_RECORD_SUCCESS,
  payload,
});

export const deleteDispatchedTripRecordError = (payload) => ({
  type: DELETE_DISPATCHED_TRIP_RECORD_ERROR,
  payload,
});

export const deleteDispatchedTripRecord = (payload) => {
  return (dispatch) => {
    dispatch(deleteDispatchedTripRecordStart());

    dispatchedTripsService
      .deleteDispatchedTripRecord({ dispatchedTripId: payload?.id })
      .then((data) => {
        if (!data.isError) {
          dispatch(deleteDispatchedTripRecordSuccess());
          dispatch(fetchDispatchedTrips(payload?.fetchDispatchedTripParams));
        } else {
          dispatch(deleteDispatchedTripRecordError(data));
        }
      });
  };
};

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const createNewDispatchedTripScreenInitStart = (payload) => ({
  type: INIT_CREATE_NEW_DISPATCHED_TRIP_SCREEN,
  payload,
});

export const initCreateNewDispatchedTripScreen = (payload) => {
  return (dispatch) => {
    dispatch(createNewDispatchedTripScreenInitStart());
    dispatch(fetchEmployees());
  };
};

// create dispatchedTrip
export const createDispatchedTripRecordStart = () => ({
  type: CREATE_DISPATCHED_TRIP_RECORD.START,
});

export const createDispatchedTripRecordSuccess = (payload) => ({
  type: CREATE_DISPATCHED_TRIP_RECORD.SUCCESS,
  payload,
});

export const createDispatchedTripRecordError = (payload) => ({
  type: CREATE_DISPATCHED_TRIP_RECORD.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createDispatchedTrip = (payload) => {
  return (dispatch) => {
    dispatch(createDispatchedTripRecordStart());
    dispatchedTripsService.createDispatchedTripRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(createDispatchedTripRecordSuccess());
        goBack()
      } else {
        dispatch(createDispatchedTripRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editDispatchedTripRecordStart = () => ({
  type: EDIT_DISPATCHED_TRIP_RECORD.START,
});

export const editDispatchedTripRecordSuccess = (payload) => ({
  type: EDIT_DISPATCHED_TRIP_RECORD.SUCCESS,
  payload,
});

export const editDispatchedTripRecordError = (payload) => ({
  type: EDIT_DISPATCHED_TRIP_RECORD.ERROR,
  payload,
});

export const editDispatchedTrip = (payload) => {
  return (dispatch) => {
    dispatch(editDispatchedTripRecordStart());
    dispatchedTripsService.editDispatchedTripRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(editDispatchedTripRecordSuccess());
        goBack()
      } else {
        dispatch(editDispatchedTripRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
