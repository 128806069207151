import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';
import { loadingsShow } from '../../Services/PostAPI';
import {
  initializeReimbursementsScreen,
  fetchReimbursements,
  deleteReimbursementRecord,
  setDeleteConfirmationPopupVisibility,
  downloadAllAttachmentForReimbursementId,
  fetchFilesByReimbursementId,
} from '../../Redux/Actions/reimbursement_new';
import { APP_URLS } from '../../constants/urlConstants';
import Reimbursements from '../../components/Reimbursement_new';
import { 
         selectIsLoading,
         selectIsLoadingPage,
         selectIsPageLevelError,
         selectIsReimbursementDeletionError,
         selectIsReimbursementDeletionSuccess,
         selectPaginationInfo,
         selectReimbursementsList,
         selectShouldOpenDeleteConfirmationPopup
        } from '../../Redux/Selectors/Reimbursement_new';


const ReimbursementsScreen = (props) => {
  const {
    reimbursementsListView,
    fetchReimbursements,
    history,
    initReimbursementScreen,
    deleteReimbursementRecord,
    isFetchingGridData,
    paginationInfo,
    showPageLevelLoader,
    screenPermissions,
    isReimbursementDeletionError,
    isReimbursementDeletionSuccess,
    isPageLevelError,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    downloadAllAttachmentForReimbursementId,
    fetchReimbursementAttachmentsByReimbursementId,
  } = props;

  useEffect(() => {
    initReimbursementScreen();
    fetchReimbursements({sortField : 'reimb_date'});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const editReimbursementRecord = (reimbursementId) => {
    const path = generatePath(APP_URLS.REIMBURSEMENTS.EDIT_REIMBURSEMENTS, { id: reimbursementId });
    history.push(path);
  };

  const createReimbursementRecord = () => {
    history.push(APP_URLS.REIMBURSEMENTS.ADD_REIMBURSEMENTS);
  };

  const moreActionProps = {
        isMoreActionDisabled : false,
        downloadEnabled :true,
        moreActionOptions : [],
        onMoreActionChange : null
  }

  const reimbursementProps = {
    reimbursementsListView,
    createReimbursementRecord,
    fetchReimbursements,
    deleteReimbursementRecord,
    editReimbursementRecord,
    isFetchingGridData,
    paginationInfo,
    screenPermissions,
    isReimbursementDeletionError,
    isReimbursementDeletionSuccess,
    isPageLevelError,
    shouldOpenDeleteConfirmationPopup,
    setDeleteConfirmationPopupVisibility,
    downloadAllAttachmentForReimbursementId,
    fetchReimbursementAttachmentsByReimbursementId,
    moreActionProps
  };
  
  if (showPageLevelLoader) {
    loadingsShow('block');
    return null;
  }
  loadingsShow('none');
  return (
    <>
      <Reimbursements {...reimbursementProps}/>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    initReimbursementScreen: () => dispatch(initializeReimbursementsScreen()),
    fetchReimbursements: (payload) => dispatch(fetchReimbursements(payload)),
    deleteReimbursementRecord: (payload) => dispatch(deleteReimbursementRecord(payload)),
    setDeleteConfirmationPopupVisibility: (payload) => dispatch(setDeleteConfirmationPopupVisibility(payload)),
    downloadAllAttachmentForReimbursementId : (payload) => dispatch(downloadAllAttachmentForReimbursementId(payload)),
    fetchReimbursementAttachmentsByReimbursementId: payload =>  dispatch(fetchFilesByReimbursementId(payload))
  };
};

// TODO(Subham):  This selection of data needs improvement, See if we can use UseSelector here.
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    reimbursementsListView: selectReimbursementsList(state),
    isFetchingGridData: selectIsLoading(state),
    showPageLevelLoader: selectIsLoadingPage(state),
    paginationInfo: selectPaginationInfo(state),
    isPageLevelError: selectIsPageLevelError(state),
    isReimbursementDeletionError: selectIsReimbursementDeletionError(state),
    isReimbursementDeletionSuccess: selectIsReimbursementDeletionSuccess(state),
    shouldOpenDeleteConfirmationPopup: selectShouldOpenDeleteConfirmationPopup(state)
    //files:selectAtta(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReimbursementsScreen);
